export const panelUI = {
  homePage: '/inventory-summary',
  drawerWidth: 300,
  drawerWidthMobile: 57,
  drawerWidthClosed: 65,
  headerHeight: 64,
  bottomCopyRightHeight: 39,
  tablePaginationHeight: 52,
  maxPageModalWidth: 1440,
  snackbarAutoHideDuration: 3000,
  inputChangeDebounce: 1000,
  fastInputChangeDebounce: 300,
  fastTooltipDelay: 300,
  tableViewMinHeight: 500,
  offsetHeightToLoad: 300,
  minModalWidth: 400,
  minLargeModalWidth: 700,
  tableRowsPerPages: [25, 50, 100],
  maxCSVDownload: 1000,
} as const
